<template>
  <div class="tree-select-outter" :class="{'tree-select-hide': (value && value.length>10) || (checkValue && checkValue.length>10) }">
    <a-tree-select style="width:100%"
      search-placeholder="请选择地区"
      ref="cascader"
      tree-checkable
      v-model="checkValue"
      :replaceFields="replaceFields"
      :load-data="onLoadData"
      :tree-data="treeData"
      show-checked-strategy="SHOW_PARENT"
      @change="onChange"
    />
    <span class="tree-tips">选项太多、自动隐藏、点击查看</span>
  </div>
</template>

<script>
import 'element-ui/lib/theme-chalk/base.css'
import 'element-ui/lib/theme-chalk/cascader.css'

import { getRegionList } from '@/api/util.js'
import _ from 'lodash'

export default {
  props: {
    value: {
    },
  },
  data() {
    return {
      refreshKey: 0,
      checkValue: undefined,
      searchState: {
        type: 1,
        parent_adcode: null
      },
      activeAdcode: 0,
      treeData: [{ name: '全国', adcode: 0, children:[], isLeaf: true }],
      reqRegionCache: {},

      replaceFields: {
        value: 'adcode',
        title: 'name',
        key: 'adcode',
        children: 'children',
      },
      props: {
        value: 'adcode',
        label: 'name',
        children: 'children',
        multiple: true,
        checkStrictly: true,
        emitPath: false,

        lazy: true,
        lazyLoad(node, resolve) {
          if (node.level < 3 && node.value != 0) {
            getRegionList({ type: node.level + 1, parent_adcode: node.value }).then((res) => {
              let nodes = res.data.list
              if (node.level == 2) {
                nodes = res.data.list.map(item => {
                  item.leaf = true
                  return item
                })
              }
              resolve(nodes);
            })
          } else {
            resolve([]);
          }
        }
      },
    }
  },
  created() {
    // if (this.value) {
    //   this.checkValue = this.value.map(item => item.adcode)
    // }
    this.initData()
  },
  methods: {
    async initData() {
      const { data } = await getRegionList(this.searchState)
      let resList = _.cloneDeep(this.treeData.concat(data.list.map(el => {
        el.type = 1
        el.children = []
        return el
      })));

      let checkValue = []

      if (this.value && this.value.length) {
        this.value.map(async (province) => {

          if(province.selectChildren && province.selectChildren.length){
            province.selectChildren.map(async (city)=>{
              if(city.selectChildren && city.selectChildren.length){
                city.selectChildren.map(async (area)=>{
                  checkValue.push(area.adcode)
                })
              }else{
                checkValue.push(city.adcode)
              }
            })
          }else{
            checkValue.push(province.adcode)
          }

          await this.parseNodeChildrent(province, resList)
          if(province.selectChildren){
            province.selectChildren.map(async (city)=>{
              await this.parseNodeChildrent(city, resList)
            })
          }
        })
        ++this.refreshKey
      }

      this.treeData = resList;
      this.checkValue = checkValue
    },

    async parseNodeChildrent(node, tree){
      const children = await this.getChildrenList({
        adcode: node.adcode, 
        type: node.type,
      })
      
      tree.find(province=>{
        if(node.type == 1){
          if(province.adcode == node.adcode){
            province.children = children
            return true
          }
        }else{
          if(node.type == 2){
            const t_city = province.children.find(city=>city.adcode==node.adcode)
            if(t_city){
              t_city.children = children
              return true
            }
          } 
        }
        return false
      })
    },

    getChildrenList(node) {
      return new Promise((resolve) => {
        if (node && node.type < 3 && node.adcode != 0) {
          getRegionList({ type: node.type + 1, parent_adcode: node.adcode }).then((res) => {
            let nodes = res.data.list

            if (node.type == 2) {
              nodes = res.data.list.map(item => {
                item.isLeaf = true
                item.type = 3
                return item
              })
            } else {
              nodes = res.data.list.map(item => {
                item.children = []
                item.type = 2
                return item
              })
            }
            resolve(nodes);
          })
        } else {
          resolve([]);
        }
      })
    },

    onLoadData(treeNode) {
      return new Promise(resolve => {
        const node = treeNode.dataRef;
        if (node.children.length > 0) {
          resolve();
        } else {
          setTimeout(async () => {
            const childArr = await this.getChildrenList(node)
            // this.treeData = this.treeData.concat(childArr);
            node.children = childArr
            this.$forceUpdate()
            resolve();
          }, 300);
        }
      });
    },


    async onChange(_, label, extral) {
      // let nodes = this.$refs.cascader.getCheckedNodes()
      let nodes = extral.allCheckedNodes.map(el => {
        if (el.node) {
          return el.node.data.props.dataRef
        } else {
          return el.data.props.dataRef
        }
      })
      if (nodes.some(item => item.adcode == 0)) { // 如果选了全国，那就返回全部省份
        const { data } = await getRegionList({
          type: 1,
          parent_adcode: null
        })
        nodes = data.list.map(item => {
          return {
            adcode: item.adcode,
            // name: item.name,
            type: 1
          }
        })
      } else {
        nodes = nodes.map(item => {
          return {
            adcode: item.adcode,
            // name: item.name,
            type: item.type
          }
        })
      }
      this.$emit('getNodes', nodes)
    },


  },
  watch: {
    value(val) {
      if (val === '') {
        this.checkValue = []
      }else{
        // this.checkValue = this.value.map(item => item.adcode)
        // this.initData()
      }
    }
  }
}
</script>

<style>
.el-cascader-menu__wrap {
  height: 400px !important;
}
.el-cascader__search-input {
  font-size: 14px;
}

.tree-select-hide .ant-select-selection__rendered {
  display: none;
}
.tree-tips{
  display: none;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  color: #999;
  line-height: 32px;
  padding-left: 10px;
}
.tree-select-hide .tree-tips{
  display: block;
}
</style>