import ajax from '@/utils/ajax.js'

/**
 * 获取手机验证码
 * @param {*} params 
 * @returns 
 */
export function getPhoneSms(params) {
  return ajax.post('/SendVerifyCode/phoneSms',params)
}


/**
 * 获取地区列表
 * @type {*} 类型 (1-省、2-市、3-区、4-街道)  
 * 
 * @returns 
 */
 export function getRegionList(params) {
  return ajax.post('/Region/getList',params)
}


/**
 * 获取手机验证码
 * @type {*} 类型 (1-列表横图，2-列表方图，3-详情横图，4-详情方图，5-详情描述图)
 * @returns 
 */
 export function uploadImg(params) {
  return ajax.post('/OssApi/upFileSTSConf',params)
}

/**
 * 获取上传配置
 * @type {*} 类型 (1-列表横图，2-列表方图，3-详情横图，4-详情方图，5-详情描述图)
 * @returns 
 */
 export function getPolicySign(params) {
  return ajax.post('/OssApi/getPolicySign',params)
}